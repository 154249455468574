<template>
	<v-container fluid tag="section">
		<new-portal-warning></new-portal-warning>
		<ti-card :icon="$icons.corporates">
			<template #title>Corporates</template>
			<template #title-actions-shown>
				<v-btn color="success" elevation="2" fab small :to="{name: 'CorporatesNew'}">
					<v-icon>{{ $icons.new }}</v-icon>
				</v-btn>
			</template>

			<v-data-table :headers="headers" :items="corporates" item-key="name" :search="search" @click:row="editItem" :item-class="() => 'hover-pointer'">
				<template v-slot:top>
					<v-row justify="end">
						<v-col cols="12" lg="6">
							<v-text-field
								v-model="search"
								label="Search"
								prepend-icon="mdi-magnify"
								class="mx-4"
							></v-text-field>
						</v-col>
					</v-row>
				</template>
			</v-data-table>
		</ti-card>
	</v-container>
</template>

<script>
import {sync} from "vuex-pathify";

export default {
	name: "CorporatesIndex",
	computed: {
		corporates: sync('corporates/corporates')
	},
	data: () => ({
		search: '',
		headers: [
			{
				text: 'Name',
				align: 'start',
				sortable: true,
				value: 'name',
			},
			{
				text: 'Status',
				align: 'start',
				sortable: true,
				value: 'corporate_status',
			},
		]
	}),
	beforeMount() {
		this.init()
	},
	methods: {
		init() {
			this.$store.set('corporates/getCorporates!', null);
		},
		editItem(data) {
			this.$router.push({name: 'CorporatesEdit', params: {id: data.id}})
		},
		filterOnlyCapsText(value, search, item) {
			return value != null &&
				search != null &&
				typeof value === 'string' &&
				value.toString().toLocaleUpperCase().indexOf(search) !== -1
		},
	}
}
</script>